import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
// !CheckLogin
import checkLogin from "../utils/checkLogin";
import checkUser from "@/utils/checkUser";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
    },
    {
      path: "/plan/:plan_name",
      name: "plan",
      component: () => import("../views/PlanView.vue"),
    },
    {
      path: "/page/:page_name/:page_id",
      name: "page",
      component: () => import("../views/EachPageView.vue"),
    },
    {
      path: "/about",
      name: "about",
      component: () => import("../views/AboutUsView.vue"),
    },
    // {
    //   path: "/MobApp",
    //   name: "MobApp",
    //   component: () => import("../views/MobAppView.vue"),
    // },
    {
      path: "/blogs",
      name: "blogs",
      component: () => import("../views/blogsView.vue"),
    },
    {
      path: "/contact-us",
      name: "ContactUs",
      component: () => import("../views/ContactUsView.vue"),
    },
    {
      path: "/Subscribe",
      name: "Subscribe",
      component: () => import("../views/SubscribeView.vue"),
    },
    {
      path: "/our-network",
      name: "OurNetwork",
      component: () => import("../views/OurNetwork.vue"),
    },
    {
      path: "/provider/:packageName",
      name: "listing",
      component: () => import("../views/EachCard.vue"),
    },
    {
      path: "/blog/:blogName",
      name: "blog",
      component: () => import("../views/EachBlogView.vue"),
    },
    {
      path: "/my-account",
      name: "my-account",
      component: () => import("../views/MyAccount.vue"),
      beforeEnter: checkUser,
    },
    {
      path: "/Login",
      name: "Login",
      component: () => import("../views/LoginView.vue"),
      beforeEnter: checkLogin,
    },
    {
      path: "/register",
      name: "Register",
      component: () => import("../views/RegisterView.vue"),
      beforeEnter: checkLogin,
    },
    {
      path: "/forget-password",
      name: "forgetPassword",
      component: () => import("../views/ForgetPasswordView.vue"),
      beforeEnter: checkLogin,
    },
    {
      path: "/reset-password",
      name: "resetPassword",
      component: () => import("../views/ResetPasswordView.vue"),
      beforeEnter: checkLogin,
    },
    {
      path: "/verify-email",
      name: "VerifyEmail",
      component: () => import("../views/VerifyEmailView.vue"),
      beforeEnter: checkLogin,
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: () => import("../views/PrivacyPolicyView.vue"),
    },
    {
      path: "/terms-and-condition",
      name: "terms-and-condition",
      component: () => import("../views/TermsAndConditionsView.vue"),
    },
    // !Not Found
    {
      path: "/:pathMatch(.*)*",
      name: "NotFound",
      component: () => import("../views/NotFoundView.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

// Export the router and the function
export default router;


// import axios from 'axios';

// async function generateSitemap() {
//   try {
//     // Fetch blogs from the API
//     // const response = await axios.get('https://app.trustksaa.com/api/v1/blogs');
//     // const response = await axios.get('https://app.trustksaa.com/api/v1/plans');
//     const response = await axios.get('https://app.trustksaa.com/api/v1/pages');
//     const blogs = response.data.data[0];

//     // Start the XML structure
//     let xml = `<?xml version="1.0" encoding="UTF-8"?>\n`;
//     xml += `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n`;

//     // Add the parent blogs page
//     xml += `  <url>\n`;
//     xml += `    <loc>https://trustksaa.com/blogs</loc>\n`;
//     xml += `    <lastmod>${new Date().toISOString()}</lastmod>\n`;
//     xml += `  </url>\n`;

//     // Add each blog dynamically
//     blogs.forEach((blog) => {
//       xml += `  <url>\n`;
//       xml += `    <loc>https://trustksaa.com/blogs/${blog.slug}/${blog.id}</loc>\n`; // Adjust according to your URL structure
//       xml += `    <lastmod>${blog.updated_at || blog.created_at}</lastmod>\n`;
//       xml += `  </url>\n`;
//     });

//     xml += `</urlset>`;

//     // Save to file
//     console.log(xml);
//     console.log('Sitemap generated successfully!');
//   } catch (error) {
//     console.error('Error generating sitemap:', error);
//   }
// }

// generateSitemap();
